// 台湾身份证验证
export function cardId(idcard){  
    if(/^[A-Z][1-2]\d{8}$/.test(idcard)) {
        var area={'A':10,'B':11,'C':12,'D':13,'E':14,'F':15,'G':16,'H':17,'J':18,'K':19,'L':20,'M':21,'N':22,'P':23,'Q':24,'R':25,'S':26,'T':27,'U':28,'V':29,'X':30,'Y':31,'W':32,'Z':33,'I':34,'O':35};
        var idcard_array = new Array();
        idcard_array = idcard.split("");
        var jym=parseInt(area[idcard_array[0]]/10)+9*(area[idcard_array[0]]%10)+8*idcard_array[1]+7*idcard_array[2]+6*idcard_array[3]+5*idcard_array[4]+4*idcard_array[5]+3*idcard_array[6]+2*idcard_array[7]+1*idcard_array[8];
        jym=(10-jym%10)%10;
        if(idcard_array[9]==jym){       
            return true;
        }
    }
    return false
}

export default {
    nickName: [
        { required: true, message: '请输入昵称', trigger: 'blur' },
        { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
    ],
    cardID: [
        { required: true, message: '身份证号不能为空', trigger: 'blur' },
        { min: 10, max: 10,message: '长度在 10 个数字', trigger: 'blur' }
    ],
    realName: [
        { required: true, message: '请输入真实姓名', trigger: 'blur' },
        { min: 2, max: 4, message: '长度在 2 到 4 个字符', trigger: 'blur' }
    ]
}