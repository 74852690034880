<template>
    <div class="details">
        <div class="state-title">
            <i class="iconfont icon-dicengjiagou"></i>
            <span>{{$t('userDetails.title')}}</span>
        </div>
        <div class="details-form">
            <el-form :model="sendForm" :rules="rules" ref="sendForm" label-width="100px" class="demo-ruleForm">
                <el-form-item :label="$t('sendUser.userName')" style="width:60%">
                    <div>{{sendForm.userName}}</div>
                </el-form-item>
                <el-form-item :label="$t('userDetails.sex')" style="width:60%">
                    <div v-if="sendForm.sex == 'women'">{{$t('grilList.sexTwo')}}</div>
                    <div v-else>{{$t('grilList.sexOne')}}</div>
                </el-form-item>
                <el-form-item :label="$t('sendUser.signature')" prop="signature">
                    <el-input size="small" v-model="sendForm.signature" :placeholder="$t('sendUser.signature')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('pay.mailbox')" prop="email" style="width:60%">
                    <el-input size="small" v-model="sendForm.email" :placeholder="$t('login.email')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('sendUser.height')" style="width:40%">
                    <el-select size="small" v-model="sendForm.height" :placeholder="this.$i18n.t('grilList.searchSelect')">
                        <el-option
                            v-for="stature in 200"
                            :key="stature"
                            :label="stature+139+'cm'"
                            :value="stature+139">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('sendUser.weight')" style="width:40%">
                    <el-select size="small" filterable v-model="sendForm.weight" :placeholder="this.$i18n.t('grilList.searchSelect')">
                        <el-option
                            v-for="weight in 200"
                            :key="weight"
                            :label="weight+34+'kg'"
                            :value="weight+34">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('userDetails.userDate')" style="width:40%">
                    <el-date-picker
                        size="small"
                        v-model="birthday"
                        @change="ageChange"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="this.$i18n.t('grilList.searchSelect')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('userDetails.address')" style="width:60%">
                    <el-input size="small" v-model="sendForm.area" :placeholder="$t('home.text')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('userDetails.marriage')" style="width:60%">
                    <el-input size="small" v-model="sendForm.marriageStatus" :placeholder="$t('home.text')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('sendUser.education')" style="width:60%">
                    <el-input size="small" v-model="sendForm.education" :placeholder="$t('home.text')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('sendUser.income')">
                    <el-select filterable  size="small" v-model="sendForm.income" :placeholder="this.$i18n.t('grilList.searchSelect')">
                        <el-option label="不足$30000" value="不足$30000"></el-option>
                        <el-option label="$45000-$60000" value="$45000-$60000"></el-option>
                        <el-option label="$60000-$75000" value="$60000-$75000"></el-option>
                        <el-option label="$75000-$150000" value="$75000-$150000"></el-option>
                        <el-option label="超過$150000" value="超過$150000"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('userDetails.occupation')" style="width:60%">
                    <el-input size="small" v-model="sendForm.occupation" :placeholder="$t('home.text')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="submitForm('sendForm')">{{$t('userLeft.success')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-dialog
            :title="$t('userDetails.headerCai')"
            :visible.sync="cropperShow"
            width="50%">
            <vueCropper
                style="width:100%;height:50vh"
                ref="cropper"
                :img="optionImg.img"
                :outputSize="optionImg.size"
                :outputType="optionImg.outputType"
                :info="true"
                :full="optionImg.full"
                :canMove="optionImg.canMove"
                :canMoveBox="optionImg.canMoveBox"
                :original="optionImg.original"
                :autoCrop="optionImg.autoCrop"
                :fixed="optionImg.fixed"
                :fixedNumber="optionImg.fixedNumber"
                :centerBox="optionImg.centerBox"
                :infoTrue="optionImg.infoTrue"
                :fixedBox="optionImg.fixedBox">
            </vueCropper>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="userImgsuccess">{{$t('userLeft.success')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data"
import {VueCropper} from 'vue-cropper'
import axios from 'axios'
import crid from '../../../assets/js/crid.js'
export default {
    components: {VueCropper},
    data(){
        return{
            cropperShow: false,
            cityData: regionData,
            birthday: "",
            cccdata: {
                imgType:'chatImg'
            },
            citys: [],
            sendForm: {},
            rules: crid,
            optionImg: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'jpg', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 300, // 默认生成截图框宽度
                autoCropHeight: 300, // 默认生成截图框高度
                fixedBox: true, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [1, 1], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: false, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            }
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        if(this.sendForm.year && this.sendForm.month && this.sendForm.day){
            this.birthday = this.sendForm.year + "-" + this.sendForm.month + "-" + this.sendForm.day
        }
        if(this.sendForm.country && this.sendForm.city && this.sendForm.area){
            this.citys.push(this.sendForm.country)
            this.citys.push(this.sendForm.city)
            this.citys.push(this.sendForm.area)
        }
    },
    methods: {
        // 年龄设置
        ageChange(e){
            this.sendForm.year = e.slice(0,4)
            this.sendForm.month = e.slice(5,7)
            this.sendForm.day = e.slice(8,10)
            this.sendForm.age = this.$isPulic.age_Conversion(e)
        },
        // 省、市、区设置
        cityChange(e){
            this.sendForm.country = e[0]
            this.sendForm.city = e[1]
            this.sendForm.area = e[2]
        },
        // 裁剪头像
        changeUpload(file, fileList) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$message.error(this.$i18n.t('userDetails.size'))
                return false
            }
            this.fileinfo = file
            // 上传成功后将图片地址赋值给裁剪框显示图片
            this.$nextTick(() => {
                this.optionImg.img = this.$isApi.http + file.response.path
                this.cropperShow = true
            })
        },
        // 上传头像
        userImgsuccess(){
            this.$refs.cropper.getCropBlob((data) => {
                let file = data
                let param = new FormData() 
                param.append('imgFile', data, 'userlogo.jpg')
                param.append('imgType', 'chatImg')
                axios.post(this.$isApi.uploadImg, param)
                .then(response => {
                    if(response.data.result == 'OK'){
                        this.sendForm.header = response.data.path
                        this.cropperShow = false
                    }
                })
            })
        },
        // 提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sendForm["type"] = 'user'
                    if(this.$i18n.locale == 'zh-cn'){
                        this.sendForm.country = "台湾"
                        this.sendForm.city = "台湾"
                    }else if(this.$i18n.locale == 'zh-tw'){
                        this.sendForm.country = "台灣"
                        this.sendForm.city = "台灣"
                    }else{
                        this.sendForm.country = "TaiWan"
                        this.sendForm.city = "TaiWan"
                    }
                    this.$isAxios.axiosPost(this.$isApi.modifyUser,this.sendForm,(res)=>{
                        if(res.data.result == 'OK'){
                            sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                            this.$message({
                                message: this.$i18n.t('userLeft.successfully'),
                                type: 'success'
                            })
                            setTimeout(()=>{
                                this.$router.go(0)
                            },1000)
                        }else{
                            this.$message({
                                message: this.$i18n.t('userLeft.noSuccessfully'),
                                type: 'warning'
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped>
.details{
    background: #fff;
    height: max-content;
    padding: 20px 20px;
}
.details-form{
    margin-top: 30px;
}
.avatar{
    width: 120px;
    height: 120px;
    background: #000;
    object-fit: scale-down
}
.el-icon-plus{
    width: 100px;
    height: 100px;
    border: 1px dashed #ccc;
    line-height: 100px;
    border-radius: 4px;
    font-size: 30px;
    color: #666;
}
</style>